import React, { Component } from "react";
import Web3 from "web3";
import { Button } from "react-bootstrap";
import { AccountInfoContext } from '../Context/AccountInfo'
import KT  from "../contracts/KillingTime.json";
import KTMint  from "../contracts/KillingTimeMint.json";

class Connect extends Component {
  
  static contextType =  AccountInfoContext
  
  componentDidMount = async () => {

    if (window.ethereum) {
      this.web3 = new Web3(window.ethereum);
    } else if (window.web3) {
      this.web3  = new Web3(window.web3.currentProvider);
    }else{
      var provider = `https://mainnet.infura.io/v3/${process.env.REACT_APP_INFURA_PROJECT_ID1}`
      var web3Provider = new Web3.providers.HttpProvider(provider);
      this.web3 = new Web3(web3Provider);
    };
    this.context.updateAccountInfo({web3: this.web3})
    if(this.web3){
      await this.setNetwork();
      await this.getContractsInstances();
      if(window.ethereum || window.web3){
        await this.setAccount();
      }
      
    }
  }

  async getContractsInstances(){
    this.networkId = await this.web3.eth.getChainId();
    this.context.updateAccountInfo({networkId: this.networkId})
    this.KTInstance = new this.web3.eth.Contract(
      KT.abi,
      parseInt(process.env.REACT_APP_GOERLI_CONTRACT_ADDRESS) && process.env.REACT_APP_GOERLI_CONTRACT_ADDRESS
    )
    this.KTMintInstance = new this.web3.eth.Contract(
      KTMint.abi,
      parseInt(process.env.REACT_APP_GOERLI_MINT_CONTRACT_ADDRESS) && process.env.REACT_APP_GOERLI_MINT_CONTRACT_ADDRESS
    )

    
    this.context.updateAccountInfo({KTInstance: this.KTInstance})
    this.context.updateAccountInfo({KTMintInstance: this.KTMintInstance})
    this.getKTInfo();
    this.getKTMintInfo();
    this.context.updateAccountInfo({instancesLoaded: true})
  }

  async setAccount(){
    if(this.context.networkId !== null){
      let accounts = await this.web3.eth.getAccounts();
      await this.context.updateAccountInfo({account: accounts[0]});
      if(this.context.account) this.getAccountsData(accounts[0])
    }else{
      this.resetAccountData();
    }
  }

  resetAccountData(){
    this.context.updateAccountInfo({
      account: null,
    })
  }

  async setNetwork(){
    // if(this.web3){
      let networkId = await this.web3.eth.getChainId();
      this.context.updateAccountInfo({networkId: networkId})
    // 
  }

  async getAccountsData(account){
    if(this.context.networkId === parseInt(process.env.REACT_APP_GOERLI_NETWORK) ){
      this.context.updateAccountInfo({walletETHBalance: await this.web3.eth.getBalance(this.context.account)});
      this.context.updateAccountInfo({KTBalance: await this.KTInstance.methods.balanceOf(this.context.account).call()});
    }
  }

  async getKTInfo(){
    if(this.context.networkId === parseInt(process.env.REACT_APP_GOERLI_NETWORK) ){
      // this.context.updateAccountInfo({price: parseInt(await this.KTMintInstance.methods._price().call())})
    }
  }

  async getKTMintInfo(){
    if(this.context.networkId === parseInt(process.env.REACT_APP_GOERLI_NETWORK) ){
      this.context.updateAccountInfo({price: parseInt(await this.KTMintInstance.methods._price().call())})
      this.context.updateAccountInfo({insurancePrice: parseInt(await this.KTMintInstance.methods._insurancePrice().call())})
      this.context.updateAccountInfo({mintOpened: await this.KTMintInstance.methods._mintOpened().call()})
    }
  }

  async connectWallet(){
    this.context.updateAccountInfo({transactionInProgress: true})
    try{
      window.ethereum.enable()
    }catch(error){
      console.log(error)
    }
    this.context.updateAccountInfo({transactionInProgress: false})
  }

  getAccountStr(account){
    let response = account.slice(0, 5) +  '...' + account.substring(account.length - 2)
    return response
  }

  renderUserInterface(){
    if(!this.context.account){
      return <Button variant="outline-light" className="interface_button" onClick={() => this.connectWallet()}>Connect</Button>
    }else if(parseInt(this.context.networkId) !== parseInt(process.env.REACT_APP_GOERLI_NETWORK)){
      return <p style={{color: 'white'}}>Please connect to {parseInt(process.env.REACT_APP_GOERLI_NETWORK) === 1 ? "Ethereum Mainnet" : "the GOERLI Network"}</p>
    }else return <Button variant="outline-light" id="interface_connection" className="interface_button">Connected as {this.getAccountStr(this.context.account)}</Button>
  }

  render() {

    if(window.ethereum || window.web3){
      if(this.web3){
        window.ethereum.on('accountsChanged', async () => {
          await this.setAccount()
        })
        window.ethereum.on('networkChanged', async () => {
          await this.setNetwork()
          await this.setAccount();
        });
  
      }
    }
    return this.renderUserInterface()
  }
  
}


export default Connect;
