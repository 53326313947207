import React, {useState, useContext} from "react";
import { Link } from "react-router-dom";
import {Container, Row, Col, Alert, Form, Button} from 'react-bootstrap'
import { AccountInfoContext } from "../Context/AccountInfo";
import clock from '../images/clock.gif'

import '../App.css'

function Home() {
    let accountInfo = useContext(AccountInfoContext)
    const [alert, setAlert] = useState({active: false, content: null, variant: null})
    const [insurance, setInsurance] = useState(false)

    function displayAlert( message, variant){
        setAlert({active: true, content: message, variant: variant})
        setTimeout(function() { setAlert({active: false, content: null, variant: null}); }, 10000);
    }

    function renderAlert(){
        if(alert.active){
            return(
            <Col className='m-'>
                <br/><br/>
                <Alert variant={alert.variant}>{alert.content}</Alert>
            </Col>
            )
        }
    }

    function handleChange(){
        setInsurance(!insurance)
    }

    async function handleMint(){
        let mint = insurance ? "an insured clock" : "an uninsured clock"
        let price = accountInfo.price
        if(insurance){
            price += accountInfo.insurancePrice
        }
        if(accountInfo.walletETHBalance < price){
            displayAlert('ETH balance insufficient', 'warning')
        }else{
            accountInfo.updateAccountInfo({userFeedback: `Minting ${mint} for ${(price)/10**18} ETH...`})
            try{
                await accountInfo.KTMintInstance.methods.mint(insurance).send({from: accountInfo.account, value: price});
                displayAlert('Mint successful!', 'success')
            }catch(error){
                displayAlert(error.message, 'warning')
            }
        }
    }

    function renderRepairLink(){
        if(!window.ethereum || !accountInfo.account){
            return null
        }else{
            if(accountInfo.KTBalance > 0){
                return(
                    <Button variant="outline-light" className="interface_button"><Link to='/repair' style={{textDecoration: 'none', color: 'white'}}>After-sales department</Link></Button>
                )
            }
        }
    }

    function renderUserInterface(){
        if(!window.ethereum || !accountInfo.account){
            return <div>Please connect your wallet</div>
        }else if(accountInfo.mintOpened){
            return(
                <Container>
                    <Row>
                        <Col className="d-flex align-items-center xs-center m-2">
                            <Form>
                                <Form.Group className="mb-3" controlId="formBasicCheckbox">
                                    <Form.Check 
                                    type="checkbox" 
                                    label={`Add insurance for ${accountInfo.insurancePrice/(10**18)} ether`} 
                                    onChange={handleChange}/>
                                </Form.Group>
                            </Form>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="d-flex xs-center m-2">
                        <Button variant="outline-light" className="interface_button" onClick={()=>handleMint()}>{`Mint for ${insurance ? (accountInfo.price + accountInfo.insurancePrice)/(10**18) : accountInfo.price/(10**18)} ether`}</Button>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="d-flex xs-center m-2">
                            {renderRepairLink()}
                        </Col>
                    </Row>
                    <Row>
                        <Col className="d-flex xs-center m-2">
                            {renderAlert()}
                        </Col>
                    </Row>
                </Container>
            )
        }else{
            return <div>Mint currently closed</div>
        }
    }

    
    return ( 
        <Container className="mb-5">
            <Row style={{fontSize: '5rem', fontWeight: 'bold'}}>
            <Col className="gothic"><Link to='/' style={{textDecoration: 'none', color: 'white'}}>Killing Time</Link></Col>
            </Row>
            <Row style={{fontSize: '1.5rem'}} className='mb-5'>
                <Col>
                    <span style={{fontSize: '1.2rem'}}>an NFT collection by Smokestacks</span><br/>
                </Col>
            </Row>
            <Row className="d-flex align-items-left justify-content-center">
                <Col xs={12} md={6}>
                    <img src={clock} className="NFT_visual" alt="clock" style={{maxHeight: '100%'}}/>
                </Col>
                <Col xs={12} md={6}>
                    <Row className="d-flex xs_center mb-5">
                        <div className="content_text xs-center ">
                            <span>Time is the most valuable asset. </span><br/><br/>
                            <span>I would suggest moving this to a hardware wallet asap...</span><br/>
                            <span>But... be careful when transporting it, if you don’t get insurance.</span><br/><br/>
                            <span>Smokestacks and Smartcontract are not responsible for any damages that may incur to uninsured works during transport and delivery...</span><br/>
                            <span>These are extremely fragile and must be handled with care.</span><br/>
                            <span>If it breaks, we provide free after-sale services. Just come back here to get it repaired</span><br/><br/>
                            <span>We however guarantee that if an insured breaks, it will only happen once...</span>
                        </div>
                    </Row>
                    <Row className="d-flex xs_center">
                            {renderUserInterface()}
                    </Row>
                </Col>
            </Row>
        </Container>
     );
}

export default Home;


