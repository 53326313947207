import React, {useState, useContext} from "react";
import {Container, Row, Col, Alert, Form, Button} from 'react-bootstrap'
import { AccountInfoContext } from "../Context/AccountInfo";
import { Link } from "react-router-dom";

import '../App.css'

function Repair() {
    let accountInfo = useContext(AccountInfoContext)
    const [alert, setAlert] = useState({active: false, content: null, variant: null})
    const [clockId, setClockId] = useState(null)

    function displayAlert( message, variant){
        setAlert({active: true, content: message, variant: variant})
        setTimeout(function() { setAlert({active: false, content: null, variant: null}); }, 10000);
    }

    function renderAlert(){
        if(alert.active){
            return(
            <Col className=''>
                <br/><br/>
                <Alert variant={alert.variant}>{alert.content}</Alert>
            </Col>
            )
        }
    }

    async function handleChange(event){
        setClockId(event.target.value);
    }

    function renderClockIdInput(){
        return(
            <Form style={{width: '200px'}} >
                <Form.Group  controlId="clockId">
                    <Form.Control 
                        type="number" 
                        min="1"
                        placeholder="Enter your clock ID"
                        value={clockId}
                        onChange={(event) => handleChange(event)}/>
                </Form.Group>
            </Form>
        )
    }

    async function handleRepair(){
        if(await checkClockOwnership()){
            let clockBroken = await accountInfo.KTInstance.methods._isBroken(clockId).call();
            if(clockBroken){
                accountInfo.updateAccountInfo({userFeedback: `Sending your clock for repair`})
                try{
                    await accountInfo.KTInstance.methods.repair(clockId).send({from: accountInfo.account});
                    displayAlert('Repair successful!', 'success')
                }catch(error){
                    displayAlert(error.message, 'warning')
                }
            }else{
                displayAlert(`Clock number ${clockId} is not broken! We can't repair it!`, 'warning')
            }
        }else{
            displayAlert(`You are not the owner of clock number ${clockId}`, 'warning')
        }
    }

    async function checkClockOwnership(){
        let owner = await accountInfo.KTInstance.methods.ownerOf(clockId).call()
        console.log(owner)
        console.log(accountInfo.account)
        return (accountInfo.account === owner)
    }

    async function activateFrame(){
        if(await checkClockOwnership()){
            accountInfo.updateAccountInfo({userFeedback: `Activating Clock Mode`})
            try{
                await accountInfo.KTInstance.methods.toggleFrameClock(clockId).send({from: accountInfo.account});
                displayAlert('Frame Clock Activated!', 'success')
            }catch(error){
                displayAlert(error.message, 'warning')
            }
        }else{
            displayAlert(`You are not the owner of clock number ${clockId}`, 'warning')
        }
    }

    function renderUserInterface(){
        if(!window.ethereum || !accountInfo.account){
            return <div>Please connect your wallet</div>
        }else{
            return(
                <Container>
                    <Row>
                        <Col className="d-flex justify-content-center">
                            {renderClockIdInput()}
                        </Col>
                    </Row>
                    <Row>
                        <Col className="m-2">
                            <Button variant="outline-light" className="interface_button" onClick={()=>handleRepair()}>{`Repair`}</Button>
                        </Col>
                        <Col className="m-2">
                            <Button variant="outline-light" className="interface_button" onClick={()=>activateFrame()}>{`Activate Frame Mode`}</Button>
                        </Col>
                    </Row>
                </Container>
            )
        }
    }

    
    return ( 
        <Container className="mb-5">
            <Row style={{fontSize: '5rem', fontWeight: 'bold'}}>
                <Col className="gothic"><Link to='/' style={{textDecoration: 'none', color: 'white'}}>Killing Time</Link></Col>
            </Row>
            <Row style={{fontSize: '1.5rem'}} className='mb-5'>
                <Col>
                    <span style={{fontSize: '1.2rem'}}>Smokestacks' clocks after-sales department</span><br/>
                </Col>
            </Row>
            <Row className="d-flex align-items-left justify-content-center">
                <Col xs={12}>
                    <Row className="d-flex justify-content-center mb-5">
                        <div className="">
                            <span>Something happened to your clock?</span><br/>
                            <span>That's embarassing... Sorry about that!</span><br/>
                            <span>We know they are fragile, but it's all good. You can repair it here! And it's free (only gas)</span><br/><br/>
                            <span>If your clock is insured, you can also activate the frame mode!</span><br/>
                        </div>
                    </Row>
                    <Row className="d-flex">
                            {renderUserInterface()}
                    </Row>
                </Col>
            </Row>
            <Row>
                {renderAlert()}
            </Row>
        </Container>
     );
}

export default Repair;


